<template>
    <div class="ContainerHeader">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'HeaderContainerOPDBooking',
};
</script>

<style scoped>
.ContainerHeader {
    /* position: fixed; */
    width: 100%;
    max-width: 425px;
    padding: 12px 12px 12px 12px;
    min-height: 48px;
    background: #48ACEF;
    text-align: left;
    /* top: 0; */
    /* z-index: 100000; */
}

</style>